import * as React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';

const baseClass = 'pn-switch';

export interface Props {
  readonly applyAccessibleStyles?: boolean;
  readonly ariaLabel?: string;
  readonly value: boolean;
  readonly onChange: (v: boolean) => void;
  readonly name?: string;
  readonly disabled?: boolean;
  readonly isInvalid?: boolean;
  readonly className?: string;
  readonly label?: string | JSX.Element;

  /**
   *  Id is required (and needs to be unique on the page).
   * Otherwise all switches with the same id will toggle together
   */
  readonly id: string;

  readonly orientation?: 'left' | 'right';
}

export const Switch: React.FC<Props> = (props) => {
  return (
    <Form.Check
      aria-label={props.ariaLabel}
      checked={props.value}
      className={classNames(baseClass, props.className, {
        'is-disabled': props.disabled,
        left: props.orientation === 'left',
        [`${baseClass}-accessible-switch`]: props.applyAccessibleStyles,
        'is-checked': props.value,
      })}
      disabled={props.disabled}
      id={props.id}
      isInvalid={props.isInvalid}
      label={props.label || ''}
      name={props.name}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(evt.target.checked)
      }
      type="switch"
    />
  );
};
