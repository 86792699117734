import * as React from 'react';
import { Card as BsCard } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';
import { handlePressEnter } from 'client/shared/core/helpers';

export interface Props {
  readonly className?: string;
  readonly interactive?: boolean;
  readonly removeExtraBorder?: boolean;
  readonly action?: () => Promise<any> | void;
  readonly rounded?: 'sm' | 'lg';
  readonly 'data-type'?: string;
}

export class Card extends React.Component<Props> {
  static readonly Img = BsCard.Img;
  static readonly Title = BsCard.Title;
  static readonly Subtitle = BsCard.Subtitle;
  static readonly Body = BsCard.Body;
  static readonly Link = BsCard.Link;
  static readonly Text = BsCard.Text;
  static readonly Header = BsCard.Header;
  static readonly Footer = BsCard.Footer;
  static readonly ImgOverlay = BsCard.ImgOverlay;

  render() {
    const p = this.props;
    const handleClick = () => p.interactive && p.action && p.action();

    return (
      <BsCard
        className={classNames('pn-card', p.className, {
          'mod-interactive': p.interactive,
          'remove-extra-border': p.removeExtraBorder,
        })}
        data-type={p['data-type']}
        onClick={handleClick}
        onKeyDown={handlePressEnter(handleClick)}
        tabIndex={0}
      >
        {p.children}
      </BsCard>
    );
  }
}
