import * as React from 'react';

import { OUTCOME_TITLE_COPY } from './copy';
import { QuestionSetType, wrap } from 'core';

interface Props {
  readonly setType: QuestionSetType;
}

export const OutcomeTitle: React.FC<Props> = (props) => {
  const title = wrap(() => {
    switch (props.setType) {
      case QuestionSetType.SET:
        return OUTCOME_TITLE_COPY.pollSetOutcome;
      case QuestionSetType.POLCO_LIVE:
        return OUTCOME_TITLE_COPY.liveEventOutcome;
      case QuestionSetType.SURVEY:
        return OUTCOME_TITLE_COPY.surveyOutcome;
    }
  });

  return <h2 className="font-weight-bold font-size-base m-0">{title}</h2>;
};
OutcomeTitle.displayName = 'OutcomeTitle';
