import { Position } from '@turf/helpers';
import states from 'us-state-codes';

export const MIN_ZOOM = 4;
export const MAX_ZOOM = 11;

export enum AreaLookupType {
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
  SUB_CITY = 'SUB_CITY',
  ANY = 'ANY',
}

export enum LocationLevel {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
}

export enum StateNameType {
  FULL_TEXT = 'FULL_TEXT',
  TWO_LETTERS = 'TWO_LETTERS',
}

const splitRegex = new RegExp('[ ,]+');
/**
 * Attempt to find the state from a string. The input from registration defaults to
 * CITY, STATE ZIP, USA. This function goes slightly more abstract and just
 * splits by comma and space and tries to find a state in the generated splits.
 * @param input
 * @param outType
 */
export function guessState(
  input: string,
  outType: StateNameType = StateNameType.FULL_TEXT
): string | null {
  // Split and then reverse, as state is most likely near the end (for example: Kansas City, MO)
  const spaceAndCommaSplits = input.split(splitRegex).reverse();

  for (const guess of spaceAndCommaSplits) {
    const state =
      states.getStateNameByStateCode(states.sanitizeStateCode(guess) || '') ??
      states.sanitizeStateName(guess);
    if (state) {
      return outType === StateNameType.TWO_LETTERS
        ? states.getStateCodeByStateName(state)
        : state;
    }
  }
  return null;
}

/**
 * To determine the size we use the following algorithm.
 * 1 - Determine the 4 most extreme points (furthers north, furthest east, etc)
 * 2 - Decide the zoom based off the max North-to-South distance. (Note: We should probably also consider
 *     the East-to-West width, but our map are all short and wide, so it is not needed now.)
 */
export function mapSizeFromCoords(points: readonly Position[]): number {
  const mostExtremeCardinalPoints = points.reduce(
    (acc, position) => {
      return {
        north: acc.north > position[1] ? acc.north : position[1],
        south: acc.south < position[1] ? acc.south : position[1],
        east: acc.east > position[0] ? acc.east : position[0],
        west: acc.west < position[0] ? acc.west : position[0],
      };
    },
    {
      north: Number.NEGATIVE_INFINITY,
      south: Number.POSITIVE_INFINITY,
      east: Number.NEGATIVE_INFINITY,
      west: Number.POSITIVE_INFINITY,
    }
  );

  const extremeNorthToSouthDistance = Math.abs(
    mostExtremeCardinalPoints.north - mostExtremeCardinalPoints.south
  );

  // These are "guesstimate values" based on trial and error. Feel free to tweak.
  // High values (11) ==> Zoomed in. Low values (4) ==> Zoomed out.
  //
  // The following questions were what I used to find these values. If you find edge cases
  // please update this list for the next person.
  // * (distance: 19.2) /n/admin (US Dashboard)
  // * (distance: 3.05) /n/admin (Wisconsin dashboard)
  // * (distance: 0.32) /n/admin (Dane County dashboard)
  // * (distance: 0.15) /n/admin/content/4ba3105e-2057-44b0-bdd1-35146f960761/question/2ad7dc75-7c37-4362-80cb-0f6dac974cfb/results/?tab=participation (Oshkosh)
  // * (distance: 0.10) /n/admin/content/d1ca01a2-4f01-47c5-84df-8c1f9b00c673/question/c10ce0d7-ded8-424b-aad8-63f1ac71e718/results/?tab=advanced_results (Round Rock)
  // * (distance: 0.09) /n/admin/content/cca31ddb-1c03-4713-9bfd-194dada826a4/question/703ed7ab-0396-474d-a426-1364473aac58/results/?tab=advanced_results (Appleton)
  // * (distance: 0.06) /n/admin/content/d37cc4d1-3336-4d5b-8c61-605455b44a64/question/c175cfba-65e9-4b32-ad3a-123b8a431191/results/?tab=advanced_results (Bar Harbor)
  if (extremeNorthToSouthDistance < 0.12) {
    return MAX_ZOOM; // This is where average sized cities land.
  } else if (extremeNorthToSouthDistance < 0.16) {
    return 10; // This is for very large cities
  } else if (extremeNorthToSouthDistance < 0.25) {
    return 9;
  } else if (extremeNorthToSouthDistance < 0.5) {
    return 8; // This is a county sized zoom
  } else if (extremeNorthToSouthDistance < 1.5) {
    return 7;
  } else if (extremeNorthToSouthDistance < 5) {
    return 6; // This is where most states land
  } else if (extremeNorthToSouthDistance < 10) {
    return 5;
  } else {
    return MIN_ZOOM; // This is the whole U.S.
  }
}

//To ensure we match hubspot's limited dropdown values
export const HubspotStateName = new Set([
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Washington DC',
  'Yukon Territories',
  'Alberta',
]);

export function guessHubspotState(stateCode: string) {
  if (stateCode === 'DC') {
    return 'Washington DC';
  }
  const state = guessState(stateCode);
  return state && HubspotStateName.has(state) ? state : null;
}
