import * as React from 'react';
import './styles.scss';
import { Editor } from '@tinymce/tinymce-react';
import { Ui, Editor as TinymceEditor } from 'tinymce';
import { Label } from 'client/shared/components/base';
import { LINK_TEMPLATE } from 'core';
import { useUpload } from 'client/shared/hooks/use-upload';

interface CustomAction {
  readonly key: string;
  readonly text: string;
  readonly onAction: (customAction: CustomAction, editor: TinymceEditor) => void;
}

export interface CustomActions {
  readonly text: string;
  readonly actions: ReadonlyArray<CustomAction>;
}

export interface Props {
  readonly customActions?: CustomActions;
  readonly className?: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly autoComplete?: string;
  readonly showErrorText?: boolean;
  readonly error?: string;
  readonly value: string;
  readonly onChange: (content: string) => void;
  readonly allowCode?: boolean;
  readonly customHeight?: string;
  readonly id: string;
}

type TinyMcePickerCallback = (uri: string, data: { readonly title: string }) => void;

export const RichText: React.FC<Props> = (props) => {
  const { uploadFile } = useUpload();
  const { customActions } = props;
  const showCustomActionsOptions = customActions?.actions.length;

  const optionalClasses = [
    props.className || '',
    props.error ? 'is-error' : '',
    props.disabled ? 'is-disabled' : 'is-enabled',
  ];

  return (
    <div className={`pn-rich-text u-opaque-on-hover ${optionalClasses.join(' ')}`}>
      {!props.disabled ? (
        <div>
          {props.label && (
            <Label
              className="pn-rich-text-label"
              htmlFor={props.id}
              text={props.label}
            />
          )}
          {process.env.NODE_ENV !== 'test' ? (
            <Editor
              aria-label={props.label || props.value}
              id={props.id}
              init={{
                setup: (editor) => {
                  showCustomActionsOptions &&
                    editor.ui.registry.addMenuButton('customActionsButton', {
                      text: customActions.text,
                      fetch: (callback) => {
                        const items: Ui.Menu.NestedMenuItemContents[] =
                          customActions.actions.map((ca) => ({
                            type: 'menuitem',
                            text: ca.text,
                            onAction: () => ca.onAction(ca, editor),
                          }));
                        callback(items);
                      },
                    });
                  editor.ui.registry.addButton('customMap', {
                    text: 'Insert Map',
                    icon: 'embed-page',
                    onAction: function (_) {
                      editor.windowManager.open({
                        title: 'Insert Map',
                        body: {
                          type: 'panel',
                          items: [
                            {
                              type: 'htmlpanel',
                              html: `Polco supports embedding your ArcGIS/Esri maps! Paste your map url here:`,
                            },
                            {
                              type: 'input',
                              name: 'map_url',
                              placeholder:
                                'https://exampleplace.maps.arcgis.com/apps/...',
                            },
                            {
                              type: 'htmlpanel',
                              html: `(Note: only urls in the format *.maps.arcgis.com are supported)`,
                            },
                          ],
                        },
                        buttons: [
                          {
                            type: 'cancel',
                            text: 'Cancel',
                          },
                          {
                            type: 'submit',
                            text: 'Insert Map',
                            buttonType: 'primary',
                          },
                        ],
                        onSubmit: ({
                          getData,
                        }: {
                          readonly getData: () => { readonly map_url: string };
                        }) => {
                          const mapUrl = getData();
                          if (/.*maps\.arcgis\.com\.*/.test(mapUrl.map_url)) {
                            editor.insertContent(
                              `<iframe width="100%" height="400px" src=${mapUrl.map_url}></iframe>`
                            );
                          }
                          editor.windowManager.close();
                        },
                      });
                    },
                  });
                },
                // Prevent unsupported fonts from being pasted in
                invalid_styles: 'font-family',
                readonly: props.disabled,
                contextmenu: false,
                plugins: `image link media lists autolink powerpaste table ${
                  props.allowCode ? 'code' : ''
                }`,
                branding: false,
                // Powerpaste plugin options
                powerpaste_word_import: 'merge',
                powerpaste_googledocs_import: 'merge',
                powerpaste_html_import: 'merge',
                powerpaste_allow_local_images: false,
                // These allow users to paste in tables
                paste_postprocess: (e) => {
                  removeTableAlignment(e);
                },
                table_default_attributes: {
                  border: '0',
                },
                // These allow users to paste in rich text
                paste_merge_formats: false,
                paste_as_text: false,
                paste_remove_styles_if_webkit: false,
                menubar: false,
                toolbar: `undo redo | bold italic underline blockquote forecolor | blocks | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media customMap ${
                  showCustomActionsOptions ? 'customActionsButton' : ''
                } | h1 h2 h3 | ${props.allowCode ? 'code' : ''}`,
                height: props.customHeight ?? '200px',
                file_picker_types: 'image',
                file_picker_callback: async (cb: Function) => {
                  // can't specify more specific type in function declaration
                  // than is known by typeof file_picker_callback, so cast here
                  const tinyMcCb = cb as TinyMcePickerCallback;
                  const res = await uploadFile();

                  if (res) {
                    tinyMcCb(res.secure_url, { title: res.original_filename });
                  }
                },
                urlconverter_callback: ensureFullUrl,
              }}
              onEditorChange={(v) => props.onChange(v)}
              value={props.value}
            />
          ) : (
            <textarea
              aria-label={props.label || props.value}
              id={props.id}
              onChange={(v) => props.onChange(v.target.value)}
              value={props.value}
            />
          )}
        </div>
      ) : (
        <div>
          {props.label && (
            <Label
              className="pn-rich-text-label"
              htmlFor={props.id}
              text={props.label}
            />
          )}
          <div
            className="pn-rich-text-view-only"
            dangerouslySetInnerHTML={{ __html: props.value }}
            id={props.id}
          />
        </div>
      )}
    </div>
  );
};

function removeTableAlignment(e: TinymceEditor) {
  const current = e.getDoc();
  const tables = current.getElementsByTagName('table');
  for (let i = 0; i < tables.length; i++) {
    tables[i].setAttribute('align', 'none');
  }
}

function ensureFullUrl(url: string): string {
  // This is a url template that we dont want to add "http://" in front of
  if (url === `{${LINK_TEMPLATE}}`) {
    return url;
  }

  const regex = new RegExp('(http:|https:)?//');
  if (!regex.test(url)) {
    return `http://${url}`;
  }
  return url;
}
