import * as React from 'react';
import * as Tableau from 'tableau-api-js';

export interface TableauReportProps {
  readonly url: string;
  readonly options: Tableau.TableauOptions;
}

export const TableauReport: React.FC<TableauReportProps> = (p) => {
  const rootDivRef = React.createRef<HTMLDivElement>();
  const vizElement = React.useRef<Tableau.Viz | null>(null);

  React.useEffect(() => {
    // Should not happen as long as we pass the ref below
    if (!rootDivRef.current) {
      return;
    }

    // If url or options change, refresh the tableau view
    if (vizElement.current) {
      vizElement.current.dispose();
      vizElement.current = null;
    }
    vizElement.current = new Tableau.Viz(rootDivRef.current, p.url, p.options);

    // Dispose of viz element on unmount
    return () => {
      if (vizElement.current) {
        vizElement.current.dispose();
        vizElement.current = null;
      }
    };
  }, [p.url, p.options, rootDivRef]);

  return <div ref={rootDivRef}></div>;
};

TableauReport.displayName = 'TableauReport';
