import * as React from 'react';
import './styles.scss';
import { Coordinate, SPATIAL_REFERENCE_ID, MAX_ZOOM, MIN_ZOOM } from 'core';
import defaultMap from './assets/default-map.png';
import iconsMapping from './assets/icons-mappin.svg';

interface ProfileMapProps {
  readonly center: {
    readonly coordinate: Coordinate;
    readonly size: number;
  } | null;
  readonly publisherName: string;
}

const baseClass = 'pn-publisher-map';

const baseBoundingBoxOffsets = {
  lat: 0.2,
  lng: 0.4,
};

export const mapSize = {
  width: 600,
  height: 350,
};

export const ProfileMap: React.FC<ProfileMapProps> = ({ center, publisherName }) => {
  if (!center) {
    return (
      <div>
        <img alt="" className={`${baseClass}-img`} src={defaultMap} />
      </div>
    );
  }

  // If the pub presides over a smaller area, then we need to zoom
  // in the bounding box (done via the scale)
  const zoomScale = MAX_ZOOM / Math.min(Math.max(center.size, MIN_ZOOM), MAX_ZOOM);
  const latOffset = baseBoundingBoxOffsets.lat * zoomScale;
  const lngOffset = baseBoundingBoxOffsets.lng * zoomScale;
  const boundingBox = [
    center.coordinate.lng + lngOffset,
    center.coordinate.lat + latOffset,
    center.coordinate.lng - lngOffset,
    center.coordinate.lat - latOffset,
  ];

  return (
    <div className={baseClass}>
      <img
        alt={`${publisherName} map`}
        className={`${baseClass}-img`}
        src={`http://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/export?bbox=${boundingBox.join(
          ','
        )}&bboxSR=${SPATIAL_REFERENCE_ID}&size=${mapSize.width},${
          mapSize.height
        }&f=image`}
      />

      <div className={`${baseClass}-pin`}>
        <img alt={`${publisherName} Map pin`} src={iconsMapping} />
      </div>
    </div>
  );
};
