import { MaterialIcon } from 'client/shared/components/base';
import React from 'react';
import { Card } from 'react-bootstrap';
import { MaterialIconName } from '../base/material-icon';
import { COLORS_JUNGLE_HEX, COLORS_JUNGLE_XD_HEX } from 'client/shared/core/colors';
import { Description } from './well-description';

export interface Props {
  readonly icon: MaterialIconName;
  readonly title?: string;
  readonly description: Description;
  readonly rightContent?: React.ReactElement;
}

const baseClass = 'pn-well';

export const Well: React.FC<Props> = ({
  title,
  description,
  icon,
  rightContent,
}) => {
  return (
    <Card
      className={`${baseClass} flex-wrap flex-column flex-lg-row justify-content-between align-items-lg-center bg-gray-5 py-2 px-3 mb-2 border-0`}
    >
      <div className={`${baseClass}-content d-flex align-items-center`}>
        <MaterialIcon icon={icon} style={{ color: COLORS_JUNGLE_HEX }} />
        <div
          className={`d-flex flex-column pl-4`}
          style={{ color: COLORS_JUNGLE_XD_HEX }}
        >
          {title ? (
            <span className="font-size-md font-weight-bold">{title}</span>
          ) : null}
          <div>
            {typeof description === 'string' ? (
              <span className="pr-1 font-size-sm">{description}</span>
            ) : (
              description
            )}
          </div>
        </div>
      </div>
      <div
        className={`d-flex justify-self-end align-items-center align-items-sm-start align-items-md-center my-2 ml-5`}
      >
        {rightContent}
      </div>
    </Card>
  );
};

Well.displayName = Well.name;
