import _ from 'lodash';
import { ClientOnlyAnalyticsEvent } from 'client/shared/graphql-client/graphql-operations.g';
import { swallowPromise } from 'core';
import React from 'react';
import { useMutationInfo } from '../containers/mutation';
import { errorLogger } from '../core/error-handler';
import { MutationInfos } from '../graphql-mutations';
import {
  CloudinaryError,
  CloudinaryResult,
  openCloudinaryUpload,
} from '../integrations/cloudinary';

export interface UseUploadOptions {
  readonly folder?: string;
  readonly clientAllowedFormats?: readonly string[]; // "image", "video", extensions w/o period
  readonly maxFileSize?: number;
  readonly multiple?: boolean;
}

interface IUseUploadState {
  readonly error: CloudinaryError | null;
  readonly fileData: CloudinaryResult | null;
}

export const useUpload = (analyticsEvent?: ClientOnlyAnalyticsEvent) => {
  const [uploadState, setUploadState] = React.useState<IUseUploadState>({
    error: null,
    fileData: null,
  });

  const { fn: trackAnalytics } = useMutationInfo(
    MutationInfos.clientOnlyAnalyticsEvent
  );

  const uploadFile = React.useCallback(
    async (options?: UseUploadOptions) => {
      if (analyticsEvent) {
        swallowPromise(
          trackAnalytics({
            variables: {
              event: analyticsEvent,
            },
          })
        );
      }
      try {
        const res = await openCloudinaryUpload(options);
        // Cloudinary does not always return the format key (in the case of zip files)
        if (res && !res.format) {
          // In cases where we don't explicitly declare a format whitelist we can leave format blank.
          const allowedFormats = options?.clientAllowedFormats || [];
          const fileExt = _.last(res.secure_url.split('.'));
          const format = fileExt && allowedFormats.includes(fileExt) ? fileExt : '';
          setUploadState({ error: null, fileData: { ...res, format } });

          return { ...res, format };
        }
        setUploadState({ error: null, fileData: res });
        return res;
      } catch (_error) {
        const error = _error as CloudinaryError;
        errorLogger.log(
          'Cloudinary upload error',
          Error(`${error.status} ${error.statusText}`),
          error
        );
        setUploadState({ error, fileData: null });
      }
    },
    [analyticsEvent, trackAnalytics]
  );

  const clearFileData = React.useCallback(() => {
    setUploadState({ error: null, fileData: null });
  }, [setUploadState]);

  return {
    uploadFile,
    clearFileData,
    fileData: uploadState.fileData,
    error: uploadState.error,
  };
};
