import { BtnLink, ButtonTypes } from 'client/shared/components/base';
import React from 'react';

export type Description = ReturnType<typeof DescriptionWithLink> | string;

export interface DescriptionWithLinkProps {
  readonly description: string;
  readonly url: string;
  readonly urlDescription: string;
  readonly anchorTarget?: React.HTMLAttributeAnchorTarget;
}

export const DescriptionWithLink: React.FC<DescriptionWithLinkProps> = ({
  description,
  url,
  urlDescription,
  anchorTarget,
}) => {
  return (
    <div>
      <div className="mb-1">{description}</div>
      <BtnLink
        className="well-description-with-link font-weight-bold font-size-base line-height-base text-jungle"
        customPadding="pt-0"
        target={anchorTarget}
        to={url}
        type={ButtonTypes.LINK}
      >
        {urlDescription}
      </BtnLink>
    </div>
  );
};

DescriptionWithLink.displayName = DescriptionWithLink.name;
