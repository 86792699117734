import escape from 'regexp.escape';

export const LINK_TEMPLATE = 'LINK';

export enum DefaultTemplateParams {
  PROFILE_NAME = 'PROFILE_NAME',
  YEAR = 'YEAR',
  PUBLISHER_NAME = 'ABC',
  PUBLISHER_TYPE = 'XYZ',
}

export interface TemplateParam {
  readonly key: string;
  readonly replaceWith: string;
}

export function getDefaultTemplateParams(
  pubName: string,
  now: Date
): readonly TemplateParam[] {
  return [
    {
      key: DefaultTemplateParams.PROFILE_NAME,
      replaceWith: pubName,
    },
    {
      key: DefaultTemplateParams.YEAR,
      replaceWith: now.getFullYear().toString(),
    },
  ];
}

export function getShareableContentDefaultTemplateParams(args: {
  readonly publisherName: string;
  readonly publisherOrgTypeName: string;
}): readonly TemplateParam[] {
  return [
    {
      key: DefaultTemplateParams.PUBLISHER_NAME,
      replaceWith: args.publisherName,
    },
    {
      key: DefaultTemplateParams.PUBLISHER_TYPE,
      replaceWith: args.publisherOrgTypeName,
    },
  ];
}

export function unTemplate(
  templatedString: string,
  templateParams: readonly TemplateParam[]
): string;
export function unTemplate(
  templatedString: string | null,
  templateParams: readonly TemplateParam[]
): string | null;
export function unTemplate(
  templatedString: string | undefined,
  templateParams: readonly TemplateParam[]
): string | undefined;
export function unTemplate(
  templatedString: string | null | undefined,
  templateParams: readonly TemplateParam[]
) {
  if (!templatedString) {
    return templatedString;
  }

  return templateParams.reduce((currentString, param) => {
    return currentString.replace(
      new RegExp(`\\{${escape(param.key)}\\}`, 'g'),
      param.replaceWith
    );
  }, templatedString);
}
