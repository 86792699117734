import * as React from 'react';
import './styles.scss';
import { Btn, ButtonTypes } from 'client/shared/components/base';
import { Result } from 'core';
import { useClock } from '../../hooks';

interface Props {
  readonly disabled?: boolean;
  readonly className?: string;
  readonly action: () => Promise<Result.Type<any, any>> | Result.Type<any, any>;
  readonly actionTextOverride?: string;
  readonly buttonType?: ButtonTypes;
}

export const SAVE_BUTTON_COPY = {
  save: 'Save',
  saved: 'Saved!',
  fail: 'Something went wrong.',
};

enum ButtonState {
  NEUTRAL = 'NEUTRAL',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

const baseClass = 'pn-save-button';

export const SaveButton: React.FC<Props> = (p) => {
  const [buttonState, setButtonState] = React.useState(ButtonState.NEUTRAL);
  const clock = useClock();

  async function handleSubmit() {
    if (buttonState !== ButtonState.NEUTRAL) {
      // This prevents a  "double clicks" which would make out timeout a little wonky
      return;
    }
    const result = await p.action();
    if (Result.isFailure(result)) {
      setButtonState(ButtonState.FAIL);
    } else {
      setButtonState(ButtonState.SUCCESS);
    }

    // wait 5 seconds and set to false
    clock.setTimeout(() => setButtonState(ButtonState.NEUTRAL), 5000);
  }

  return (
    <Btn
      action={handleSubmit}
      className={`${p.className ?? ''} ${
        buttonState === ButtonState.SUCCESS
          ? `bg-polco-green-xd text-white ${baseClass}-success`
          : buttonState === ButtonState.FAIL
          ? `${baseClass}-fail bg-white`
          : ''
      }`}
      disabled={p.disabled}
      type={ButtonTypes.PRIMARY}
    >
      {buttonState === ButtonState.NEUTRAL
        ? p.actionTextOverride ?? SAVE_BUTTON_COPY.save
        : buttonState === ButtonState.SUCCESS
        ? SAVE_BUTTON_COPY.saved
        : SAVE_BUTTON_COPY.fail}
    </Btn>
  );
};
